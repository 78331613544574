import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  private _allStepsData = {};
  private countryCode: string;
  private selectedValue = {};
  private allStepData;
  private type;
  uploadAllFileSize = 0;
  countryList: any;
  set(name: string, data): void {
    try {
      this._allStepsData['' + name] = JSON.parse(JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  }

  get(name: string): any {
    return this._allStepsData['' + name];
  }


  setCountryList(data){
    this.countryList=data
  }

 
  getCountryList(){
    return this.countryList;
  }
 

  clear(): void {
    this._allStepsData = {};
  }



  setDataClear() {
    this.allStepData = {};
    this.type = null;
    this._allStepsData = {};
  }

  setCountryCode(countryCode): void {
    this.countryCode = countryCode;
  }

  getCountryCode(): string {
    return this.countryCode;
  }
  getAllStepData(): Array<any> {
    return this.allStepData;
  }


  setAllStepData(allStepData): void {
    this.allStepData = allStepData;
  }
  setType(type: string) {
    this.type = type;
  }
  getType() {
    return this.type;
  }
  clearPaymentDocument(): void {
    delete this._allStepsData['' + 'fnol-payment-details']
    delete this._allStepsData['' + 'fnol-upload-docs']
  }
  setSelectedData(selectedValue): void {
    this.selectedValue = selectedValue;
  }
  getSelectedData() {
    return this.selectedValue;
  }

  getuploadAllFileSize() {
    return this.uploadAllFileSize
  }

  setuploadAllFileSize(e) {
    this.uploadAllFileSize = e
  }
  checkAutoComplete(value) {
    if (typeof value === 'object' && value.autoCompleteAddress) {
      return (value['name'] || value.autoCompleteAddress)
    } else {
      return value
    }
  }
  constructor() { }
}
