import { IsubmitFeedbackModel } from './../shared/model/submit-feedback-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { throwError as observableThrowError, BehaviorSubject, Observable, Subject } from 'rxjs';
import { LoadingService } from "axis-components";
import { catchError, map } from 'rxjs/operators';
import { CHECKBOXHEADINGLITETravel, CHECKBOXLITEPERSONAL, FNOL_COUNTRY, FNOL_STEPS_JP } from '../config/fnol-constants';
import { Configuration } from '../../../../app/shared/configuration';
import { URL_PARAMETERS } from '../config/urlParameters.constant';
import * as CryptoJS from 'crypto-js';
import { submitClaim } from '../shared/model/submit-claim-nordics';
import { CacheService } from 'src/app/shared/services/cache.service'
import * as moment from 'moment';
import { DataStoreService } from './data-store.service';
import { cdpLite } from '../cdp-lite/cdp-lite/services/cdp-lite-model';
import { Utils } from 'axis-ui-generator';

@Injectable({
  providedIn: "root",
})
export class FnolService {
  public pageDetail = new BehaviorSubject<object>({ step: "", age: "" });
  public copyAccount = new BehaviorSubject<any>({});
  public claimType = new BehaviorSubject<object>({ type: "" });
  public setClaim = new BehaviorSubject<object>({});
  public claimDetails = new BehaviorSubject<object>({ item: "" });
  public namEstimated = new BehaviorSubject<object>({
    lossCurrencyType: "",
    lossCurrencyCargo: "",
    cargo: "",
    loss: "",
  });
  public openPopUpEvent = new BehaviorSubject<Boolean>(false);
  public termEvent = new BehaviorSubject<Boolean>(false);
  public continueClickEvent = new Subject<object>();
  public setRequire = new BehaviorSubject<Boolean>(false);
  public openFromPolicyDashBoard = new BehaviorSubject<Boolean>(false);
  public upload = new BehaviorSubject<object>({ upload: "" });
  public errorEvent = new Subject<String>();
  public manualPolicyError = new BehaviorSubject(false);
  public landingSelectedCountry = new BehaviorSubject<{ code: string; label: string; name: string; }>({
    code: '',
    label: '',
    name: '',
  });
  private setCurrency = "";
  headerTransactionId: string = "";
  counterFromDashboarForSavedClaim: any;
  feedbackRequestData;
  updateDocTypeRequest = [];
  userid;
  baseUrl = "assets/data/locale";
  policyDetails: any;
  phoneCodeList: any;
  statusApp: any;
  countryKey: any;
  maintanenceApiChecked = false;
  isPolicyValidated: boolean;
  claimantAllUIData: any;
  claimtypeAllUIData: any;
  claimTypeAllStructureData: any;
  saveCounter: boolean = false;
  countFlag: boolean = true;
  setSectionName: any;
  savedClaimantDetails: any;
  savedDraft: any = [];
  paymentAllUIData: any;
  UploadData: any;
  Step2UIStructure: any;
  gridAvailable: any;
  savedClaimTypeDetails: any;
  savedClaimTypeStructureDetails: any;
  uploadDetails: any = {};
  claimBenifitSelected: any;
  parentNumberForSaveDraft: any;
  paymentdetails: any = {};
  clickedFromDashboard: boolean = true;
  showPolicyPopUP: boolean = true;
  SelectedType: any;
  FollowUpForSavedDraft: any = false;
  constructor(
    private http: HttpClient,
    private config: Configuration,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router,
    private dataStoreService: DataStoreService,
    private cacheService: CacheService,
  ) {
    this.config.maintanenceSubject.subscribe(res => {
      if (res === 'checkMaintanenceApiStatus') {
        this.pageRouting();
      }

    });
  }
  oneTimeCAll
  setUrlParameters(): void {


    URL_PARAMETERS.COUNTRY = this.route.snapshot.queryParamMap.get("country") || this.cacheService.get('country').toUpperCase();
    URL_PARAMETERS.LANGUAGE = this.route.snapshot.queryParamMap.get("language");
    URL_PARAMETERS.POLICY_NO =
      this.route.snapshot.queryParamMap.get("policyNo");
    URL_PARAMETERS.LOB = this.route.snapshot.queryParamMap.get("lob");
    URL_PARAMETERS.PARTNER = this.route.snapshot.queryParamMap.get("partner");
    URL_PARAMETERS.TYPE = this.route.snapshot.queryParamMap.get("type");
    URL_PARAMETERS.CAPTCHA = this.route.snapshot.queryParamMap.get("skipCaptcha");
    if (URL_PARAMETERS.COUNTRY && URL_PARAMETERS.COUNTRY.toLowerCase() !== "jp") {
      if (
        !URL_PARAMETERS.COUNTRY ||
        !URL_PARAMETERS.LANGUAGE ||
        !URL_PARAMETERS.LOB
      ) {
        this.router.navigate(["cdp/error"]);
      }
    }
  }

  getClaimBenifitSelected() {
    return this.claimBenifitSelected;
  }
  setDataCurrency(data) {
    this.setCurrency = data;
  }
  getDataCurrency() {
    return this.setCurrency;
  }
  getParentNumberForSaveDraft() {
    return this.parentNumberForSaveDraft;
  }

  getFollowUpForSavedDraft() {
    return this.FollowUpForSavedDraft;
  }

  getSelectedType() {
    return this.SelectedType;
  }

  getSavedClaimtypeDetails() {
    return this.savedClaimTypeDetails;
  }

  getSavedClaimTypeStructureDetails() {
    return this.savedClaimTypeStructureDetails;
  }

  getUploadDetails() {
    return this.uploadDetails;
  }

  getpaymentdetails() {
    return this.paymentdetails;
  }

  getStep2UIStructure() {
    return this.Step2UIStructure;
  }

  getGridAvailable() {
    return this.gridAvailable;
  }

  getpaymentAllUIData() {
    return this.paymentAllUIData;
  }

  getUploadData() {
    return this.UploadData;
  }

  getSavedDraft() {
    return this.savedDraft;
  }

  getCounterFromDashboarForSavedClaim() {
    return this.counterFromDashboarForSavedClaim;
  }

  setUrlParametersSingapore(): void {
    URL_PARAMETERS.COUNTRY = this.route.snapshot.queryParamMap.get("country");
    URL_PARAMETERS.POLICY_NO =
      this.route.snapshot.queryParamMap.get("policyNo");
    URL_PARAMETERS.LANGUAGE = this.route.snapshot.queryParamMap.get("language");
    URL_PARAMETERS.LOB = this.route.snapshot.queryParamMap.get("lob");
    URL_PARAMETERS.CAPTCHA =
      this.route.snapshot.queryParamMap.get("skipCaptcha");
  }


  setUrlParametersLite(): void {
    const path = window.location.pathname.split("/")[1];
    URL_PARAMETERS.COUNTRY = path;
  }

  getSavedClaimantDetails() {
    return this.savedClaimantDetails;
  }

  set(keys, value) {
    const encrypted = CryptoJS.AES.encrypt(value, keys);

    return encrypted.toString();
  }

  getSetSectionName() {
    return this.setSectionName;
  }

  getSaveCounter() {
    return this.saveCounter;
  }

  setSaveCounter(val: boolean) {
    this.saveCounter = val
  }

  getcountFlag() {
    return this.countFlag;
  }

  clearTheValues() {
    this.claimantAllUIData = null;
    this.claimTypeAllStructureData = null;
    this.savedClaimTypeDetails = null;
    this.claimtypeAllUIData = null;
    this.UploadData = null;
    this.paymentAllUIData = null;
    this.clickedFromDashboard = false;
    this.saveCounter = false;
    this.gridAvailable = [];
  }
  get(keys, value) {
    const decrypted = CryptoJS.AES.decrypt(value, keys);

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  getAUGJson(fileName: string): Observable<any> {
    let countryUrl: string;
    let country =
      URL_PARAMETERS.COUNTRY?.toLowerCase() || localStorage.getItem("country");
    switch (country) {
      case FNOL_COUNTRY.AUSTRALIA.countryCode:
        if (URL_PARAMETERS.TYPE === "CAT") {
          countryUrl = this.baseUrl + "/en-au-cat/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-au/" + fileName + ".json";
        }
        break;
      case FNOL_COUNTRY.NAM.countryCode:
        countryUrl = this.baseUrl + "/en-us/" + fileName + ".json";
        break;

      case FNOL_COUNTRY.NEWZEALAND.countryCode:
        if (URL_PARAMETERS.TYPE === "CAT") {
          countryUrl = this.baseUrl + "/en-nz-cat/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-nz/" + fileName + ".json";
        }

        break;

      case FNOL_COUNTRY.SINGAPORE.countryCode:
        countryUrl = this.baseUrl + "/en-sg/" + fileName + ".json";
        break;

      case FNOL_COUNTRY.SINGAPORENEW.countryCode:
        countryUrl = this.baseUrl + "/en-singapore/" + fileName + ".json";
        break;
      case FNOL_COUNTRY.LITE.countryCode:
        countryUrl = this.baseUrl + "/en-lite/" + fileName + ".json";
        break;
      case FNOL_COUNTRY.VIETNAM.countryCode:
        if (URL_PARAMETERS.LANGUAGE === FNOL_COUNTRY.VIETNAM.language) {
          countryUrl = this.baseUrl + "/vn-vn/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-vn/" + fileName + ".json";
        }
        break;

      case FNOL_COUNTRY.THAILAND.countryCode:
        if (URL_PARAMETERS.LANGUAGE === FNOL_COUNTRY.THAILAND.language) {
          countryUrl = this.baseUrl + "/th-th/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-th/" + fileName + ".json";
        }
        break;
      case FNOL_COUNTRY.JAPAN.countryCode:
        countryUrl = this.baseUrl + "/ja-ja/" + fileName + ".json";

        break;

      default:
        countryUrl = fileName + ".json";

    }
    return this.http.get(countryUrl);
  }

  getProductName(policy) {
    if (policy?.productInfo && this.cacheService.get("language")) {
      if (this.cacheService.get("language").toLowerCase() === "jp") {
        return policy?.productInfo?.productName_jp
          ? policy?.productInfo?.productName_jp
          : "";
      } else {
        return policy?.productInfo?.productName_en
          ? policy?.productInfo?.productName_en
          : "";
      }
    } else {
      return policy && policy?.productInfo ? policy?.productInfo?.productName_en : '';
    }
  }

  makeAddPolicyModel(selectedRows): any {
    let selectedRowsModified = []
    selectedRows.forEach((row) => {
      let policyObj = { policy: {} }

      policyObj["policy"]["emailId"] = this.cacheService.getUserId();
      policyObj["policy"]["policyDetailsJson"] = row;

      policyObj["policy"]["statusCd"] = "Active";
      policyObj["policy"]["countryCd"] = "JP";
      delete policyObj["policy"]['policyDetailsJson']['selected'];
      selectedRowsModified.push(policyObj)
    });
    return selectedRowsModified;
  }

  setAppStatus(response) {
    this.statusApp = response
  }

  getAppStatus() {
    return this.statusApp
  }

  getCountry() {
    return this.countryKey
  }

  setCountry(response) {
    this.countryKey = response
  }

  getPagestatus() {
    const pagestat = window.location.pathname.split("/")[3]
    if ((pagestat === "unavailable") || (pagestat === "undermaintenance")) {
      return true
    } else {
      return false
    }
  }


  pageRouting(): void {
    if (this.maintanenceApiChecked) {
      return;
    }
    this.loadingService.stop()
  }

  updateAnalyticsData(name, event) {
    window['digitalData']['page']['pageInfo']['cdpPname'] = name;
    window['digitalData']['page']['pageInfo']['cdpEvent'] = event;
  }
  updateAnalyticsDataNordics(name) {

    window['digitalData'] =
    {
      "site": { "name": "Claims Digital Portal", "country": window.location.pathname.split('/')[3].toUpperCase(), "language": window.location.pathname.split('/')[4].toUpperCase(), "lob": window.location.pathname.split('/')[5].toUpperCase() },
      "page": { "name": name, "claimtype": this.dataStoreService.get('fnol-landing') ? this.dataStoreService.get('fnol-landing').uiStructure[0].value : "" }
    }
  }
  updateAnalyticsDataLite(name, appCountry: string) {
    if (name === "Personal Information") {
      window['digitalData']['event'] = "form loaded"
    } else if (name === "Success") {
      window['digitalData']['event'] = "form complete"
    } else {
      window['digitalData']['event'] = ""
    }
    window['digitalData'].site = {
      "name": "cdp-lite",
      "country": appCountry,
      "language": "EN",
      "lob": "claims",
      "environment": window.location.href.indexOf('prod') > -1 ? "prod" : "dev"
    };
    window['digitalData'].page.name = "cdp-lite:" + name;
    window['digitalData'].claimInfo.claimType = this.dataStoreService.get('fnol-claimtype-details') ? this.dataStoreService.get('fnol-claimtype-details').uiData["claimBenefits"] : ""
    window['digitalData'].claimInfo.claimNumber = this.cacheService.getClaimNumber() ? this.cacheService.getClaimNumber() : ""
    window['digitalData'].claimInfo.claimReason = this.getClaimReason()
    window['digitalData'].form.formId = this.cacheService.getClaimNumber() ? this.cacheService.getClaimNumber() : ""
    window['digitalData'].form.formStepName = name

  }

  getClaimReason() {
    let newArray = []
    if (this.dataStoreService.get('fnol-claimtype-details')?.uiData["claimBenefits"] === 'Personal Accident') {
      if (this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimType']) {
        this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimType'].forEach(element => {
          if (CHECKBOXLITEPERSONAL.find(field => field.key === element.name) != undefined) {
            const dataObj = Utils.getFieldFromStructure(this.dataStoreService.get('fnol-claimtype-details')?.uiStructure, element.name)
            let headingValue = dataObj.heading;
            newArray.push(headingValue);
          }
        });
        return newArray.join(',');
      }
    } else if (this.dataStoreService.get('fnol-claimtype-details')?.uiData["claimBenefits"] === "Travel") {
      this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness']?.forEach(element => {
        if (CHECKBOXHEADINGLITETravel.find(field => field.key === element.name) != undefined) {
          const dataObj = Utils.getFieldFromStructure(this.dataStoreService.get('fnol-claimtype-details')?.uiStructure, element.name)
          let headingValue = dataObj.heading;
          newArray.push(headingValue);
        }
        // newArray.push(element.name);
      });
      return newArray.join(',')
    }
    else {
      return this.dataStoreService.get('fnol-claimtype-details') ? this.dataStoreService.get('fnol-claimtype-details')?.uiData["claimForOther"] : ""
    }
  }

  setFeedbackRequestData(feedbackReqData): void {
    this.feedbackRequestData = feedbackReqData;
  }
  getUpdateDocTypeRequest(uploadedFiles) {
    const req = [];
    let country = URL_PARAMETERS.COUNTRY.toLowerCase() || localStorage.getItem("country") || window.location.pathname.split("/")[3]
    uploadedFiles.forEach((upload) => {
      if (country === FNOL_COUNTRY.JAPAN.countryCode) {
        let mergereq = {
          ...upload.uploadReqObj,
          ...{
            cmDocType: upload.claimBenefitType,
            userId: this.cacheService.getUserId(),
            documentName: upload.name,
            documentId: upload.docId,
            documentClassification: upload.supportDocType?.code,
            documentClassificationName: upload.supportDocType?.name,
          },
        };
        req.push(mergereq);
      } else {
        let mergereqNonJpn = {
          ...upload.uploadReqObj,
          ...{
            cmDocType: upload.claimBenefitType,
            userId: this.cacheService.getUserId() ? this.cacheService.getUserId() : this.config.userEmailID,
            documentName: upload.name,
            documentId: upload.docId
          },
        };
        req.push(mergereqNonJpn);
      }
    });
    this.updateDocTypeRequest = req;
  }
  updateDocType() {
    const requestObj = JSON.stringify({
      updateDocRequest: this.updateDocTypeRequest,
    });
    const options = this.createSubmitHeaders();
    const unrDocUploadURL = this.config.apiEndPoints_cdp_lite.unrUploadDocURL + "/docInfo";
    return this.http.put(unrDocUploadURL, requestObj, options);
  }
  getFeedbackRequestData(): any {
    return this.feedbackRequestData;
  }

  getDataByLevel(level, addressList: any[]): any {
    let data = "";
    if (addressList && addressList.length > 0) {
      addressList.forEach((element) => {
        if (element && element.hasOwnProperty("types")) {
          if (element.types.indexOf(level) > -1) {
            data = element.long_name;
          }
        }
      });
    }

    return data;
  }

  getDataByCode(level, addressList: any[]): any {
    let data = "";
    addressList.forEach((element) => {
      if (element && element.hasOwnProperty("types")) {
        if (element.types.indexOf(level) > -1) {
          data = element.short_name;
        }
      }
    });
    return data;
  }

  createHeaders(): any {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        Authorization: "Basic " + this.config["authorizationKey_new"],
      }),
    };
  }

  createdocHeaders(): any {
    return {
      headers: new HttpHeaders({
        "email-id": this.config.userEmailID,
        transactionId: this.headerTransactionId,
        Authorization: "Basic " + this.config["authorizationKey_new"]
      }),
    };
  }

  docHeaderJP(): any {
    return {
      headers: new HttpHeaders({
        transactionId: this.headerTransactionId,
      }),
    };
  }

  createSubmitHeaders(): any {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        transactionId: this.headerTransactionId,
        Authorization: "Basic " + this.config["authorizationKey_new"],
      }),
    };
  }

  createFeedbackHeaders() {
    return this.makeHttpOptionsObject("submit-feedback");
  }

  createClaimNumberHeaders() {
    return this.makeHttpOptionsObject("doc-upload");
  }

  makeHttpOptionsObject(resource) {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        transactionId: this.headerTransactionId,
        Authorization: "Basic " + this.config["authorizationKey_new"],
      }),
    };
  }

  setTransactionId(transactionId) {
    this.headerTransactionId = transactionId;
  }
  getTransactionId() {
    return this.headerTransactionId;
  }

  createHeadersJP(headers1?): any {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
    };
    return httpOptions;
  }
  docUploadRequest(req): any {
    const options = URL_PARAMETERS.COUNTRY.toLowerCase() === '' ? this.cacheService.get('country').toLowerCase() : this.createdocHeaders();
    const unrDocUploadURL = URL_PARAMETERS.COUNTRY.toLowerCase() === '' ? this.cacheService.get('country').toLowerCase() : this.config.apiEndPoints_cdp_lite.unrUploadDocURL;
    return this.makeHttpPostCall(unrDocUploadURL, req, options);
  }

  docUploadRequestNordics(req): any {
    const options = this.createdocHeaders();
    const unrDocUploadURL = this.config.apiEndPoints_cdp_lite.unrUploadDocURL;
    return this.makeHttpPostCall(unrDocUploadURL, req, options);
  }

  logErrorAEM(eventName, req, res, from) {
    window["claimsDigital"][from]["req"] = req;
    window["claimsDigital"][from]["res"] = res;
    const event = new CustomEvent(eventName);
    document.body.dispatchEvent(event);
  }

  finalClaimSubmit(req) {
    const requestObj = JSON.stringify(req);
    const options = this.createSubmitHeaders();
    const unrFinalSubmitURL = this.config.apiEndPoints_cdp_lite.setFnolURL;
    return this.http.put(unrFinalSubmitURL, requestObj, options);
  }

  deleteDocRequest(req): any {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",

      }),
      body: req,
    };
    const unrDeleteDocUploadURL = this.config.apiEndPoints_cdp_lite.unrUploadDocURL;
    return this.http.delete(unrDeleteDocUploadURL, options);
  }

  getUnrRdfEnumList(enumListModel): any {
    const unrRdfEnumURL =
      "assets/enumerationResponse/" + enumListModel + ".json";
    return this.http.get(unrRdfEnumURL);
  }

  // not this one
  getClaimNumber(): Observable<object> {
    const options = this.createClaimNumberHeaders();
    const unrGetClaimNumURL = this.config.apiEndPoints_cdp_lite.unrGenerateClaimNumberCommon +
      (URL_PARAMETERS.COUNTRY || window.location.pathname.split("/")[3].toUpperCase());
    return this.http.get(unrGetClaimNumURL, options);
  }

  getclaimantAllUIData() {
    return this.claimantAllUIData;
  }

  getclaimtypeAllUIData() {
    return this.claimtypeAllUIData;
  }

  getClaimTypeAllStructureData() {
    return this.claimTypeAllStructureData;
  }

  getFnolRequestBody(
    type: string = "",
    policyDetailsData: any = {},
    phoneCodeListData: any = []
  ) {
    let allStepData = [];
    Object.keys(FNOL_STEPS_JP).map((val) => {
      if (val !== "DECLARATION") {
        allStepData.push(this.dataStoreService.get(FNOL_STEPS_JP[val].name));
      }
    });
    this.policyDetails = policyDetailsData;
    this.phoneCodeList = phoneCodeListData;

    const requestObj = [];
    cdpLite["applicationContext"]["countryCode"] = URL_PARAMETERS.COUNTRY;
    cdpLite["applicationContext"]["userId"] =
      this.cacheService.getUserId();
    cdpLite["claimsDetails"]["userId"] = this.cacheService.getUserId();
    cdpLite["claimsDetails"]["claimNumber"] = this.cacheService.getClaimNumber();
    cdpLite["claimsDetails"]["dateofIncident"] = moment(
      allStepData[0].uiData.accidentDate
    ).format("YYYY-MM-DD");

    requestObj.push(cdpLite);



    return Object.assign({}, ...requestObj);
  }

  seFnolApiOneTimeCall(data) {
    this.oneTimeCAll = data
  }


  generateSetFnolHeader(): any {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        transactionID: this.getTransactionId() || "",
        Authorization: "Basic " + this.config["authorizationKey_new"],
      }),
    };
  }

  setFnol(): Observable<object> {
    const setFnolURL = this.config.apiEndPoints_cdp_lite.setFnolURL;
    const options = this.generateSetFnolHeader();
    const requestObj = "";
    return this.makeHttpPostCall(setFnolURL, requestObj, options);
  }

  generateSetFnolRequestNordics() {

    let applicationContext = {
      "countryCode": window.location.pathname.split("/")[3].toUpperCase(),
      "language": window.location.pathname.split("/")[4],
      "lineOfBusiness": window.location.pathname.split("/")[5].toUpperCase(),
      "userId": this.config.userEmailID,
      "claimNumber": this.cacheService.getClaimNumber(),
      "subLob": "NA",
      "submissionDate": moment(new Date()).format("DD-MM-YYYY"),
      "submissionTime": moment(new Date()).format("HH:mm:ss")
    }

    return applicationContext;

  }

  setFnolNordics(): Observable<object> {
    const setFnolURL = this.config.apiEndPoints_cdp_lite.setFnolURL;
    const options = this.generateSetFnolHeader();
    const requestObj = { ...submitClaim, applicationContext: this.generateSetFnolRequestNordics() };
    return this.http.post(setFnolURL, requestObj, options);
  }

  submitFeedback(request: IsubmitFeedbackModel) {
    const requestObj = JSON.stringify(request);
    const options = this.createFeedbackHeaders();
    const unrFeedBackNewURL = this.config.apiEndPoints_cdp_lite.unrFeedBackNewURL;
    return this.makeHttpPostCall(unrFeedBackNewURL, requestObj, options);
  }

  makeHttpPostCall(url, data, options): any {
    return this.http.post(url, data, options).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error || "Server error");
      })
    );
  }

  getAddWitnessData(index, getPosition, witnessObj, controlsToBeAddDelete) {
    const addWitnessData = JSON.parse(JSON.stringify(witnessObj)); // [...ClaimInfoData.ADDWITNESS];
    for (let i = 0; i < addWitnessData.length; i++) {
      if (addWitnessData[i].name === "thirdPartyWitness1Title") {
        addWitnessData[i].value = `Third Party/Witness ${getPosition}`;
      }
      if (
        addWitnessData[i].name === "customAddWitness" ||
        addWitnessData[i].name === "customDeleteWitness"
      ) {
        addWitnessData[i].index += index;
      }
      if (addWitnessData[i].name === "customDeleteWitness") {
        addWitnessData[i].controlsToDelete = controlsToBeAddDelete;
      }
      addWitnessData[i].name = `${addWitnessData[i].name}${index}`;
      addWitnessData[i]["index"] = index;
      if (addWitnessData[i].fields && addWitnessData[i].fields.length) {
        for (let j = 0; j < addWitnessData[i].fields.length; j++) {
          addWitnessData[i].fields[
            j
          ].name = `${addWitnessData[i].fields[j].name}${index}`;
          addWitnessData[i].fields[j]["index"] = index;
        }
      }
    }
    return addWitnessData;
  }

  setIsValidated(val: boolean) {
    this.isPolicyValidated = val
  }

  getIsValidated() {
    return this.isPolicyValidated
  }
  checkAccountNum(confirmNum, uiData, fieldName) {

    if (fieldName === "confirmAccountNumberN") {
      return (confirmNum === uiData["accountNumberN"] ? true : false);
    } else if (fieldName === "confirmAccountNumberF") {
      return (confirmNum === uiData["accountNumberF"] ? true : false);
    } else if (fieldName === "confirmAccountNumberN2") {
      return (confirmNum === uiData["accountNumberN2"] ? true : false);
    } else if (fieldName === "confirmAccountNumberF2") {
      return (confirmNum === uiData["accountNumberF2"] ? true : false);
    }
  }

}
