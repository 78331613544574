import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable()
export class CacheService {
  // storage type supported
  public static LOCAL_STORAGE: string = "LOCAL_STORAGE";
  public static SESSION_STORAGE: string = "SESSION_STORAGE";

  public static COOKIE_STORAGE: string = "COOKIE_STORAGE";

  // keys used for storing in cache
  public IDTOKEN: string = "idtoken";
  public ACCESSTOKEN: string = "accesstoken";

  public REFRESHTOKEN: string = "refreshtoken";
  public USERID: string;
  public USERFULLNAME: string = "userfullname";
  public ACCEPTED_LIC_AGREEMENT: string = "licagreement";
  claimNumberGenerated: string;
  claimNumberGeneratedFollowUp: string;
  alreadyGeneratedClaimNumber: any;
  country:string;
isMTARouting=false;
  constructor() { }

  public put(key: string, value: string, storageType?: string) {
    this.remove(key, storageType);

    if (storageType && storageType.toLowerCase() === "session") {
      sessionStorage.setItem(key, value);
    } else if (storageType && storageType.toLowerCase() === "local") {
      localStorage.setItem(key, value);
    } else if (storageType && storageType === CacheService.COOKIE_STORAGE) {
      this.setCookie(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  }

  public get(key: string, storageType?: string): string {
    let returnVal = "";
    if (storageType && storageType.toLowerCase() === "session") {
      returnVal = sessionStorage.getItem(key)
        ? sessionStorage.getItem(key)
        : "";
    } else if (storageType && storageType.toLowerCase() === "local") {
      returnVal = localStorage.getItem(key)
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    } else if (storageType && storageType === CacheService.COOKIE_STORAGE) {
      returnVal = this.getCookie(key);
    } else {
      returnVal = localStorage.getItem(key)
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    }

    return returnVal;
  }

  public remove(key: string, storageType?: string) {
    if (storageType && storageType.toLowerCase() === "session") {
      sessionStorage.removeItem(key);
    } else if (storageType && storageType.toLowerCase() === "local") {
      localStorage.removeItem(key);
    } else if (storageType && storageType === CacheService.COOKIE_STORAGE) {
      this.deleteCookie(key);
    } else {
      localStorage.removeItem(key);
    }
  }

  public clearAll() {
    sessionStorage.clear();
    localStorage.clear();
    this.deleteAllCookies();
  }

  getGeneratedClaimNumber() {
    return this.alreadyGeneratedClaimNumber;
  }

  private setCookie(name: string, value: string) {
    document.cookie = name + "=" + value;
  }

  private getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;

    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }

  private deleteCookie(cookieName: string) {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name === cookieName) {
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        break;
      }
    }
  }

  setClaimNumber(claimNumber) {
    this.claimNumberGenerated = claimNumber;
  }


  getClaimNumber() {
    return this.claimNumberGenerated;
  }

  setMTA(){
    this.isMTARouting=true;
  }
  getMTA(){

    return this.isMTARouting;
  }
   
  setFollowUpClaimNumber(claimNumber) {
    this.claimNumberGeneratedFollowUp = claimNumber;
  }


  getFollowUpClaimNumber() {
    return this.claimNumberGeneratedFollowUp;
  }

  setUserid(res) {
    this.USERID = res;
  }
  getUserId() {
    return this.USERID;
  }

  setCountry(country){
   this.country=country;
  }
  getCountry(){
    return this.country;
  }
  private deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  private user = new BehaviorSubject<boolean>(false);
  public castUser = this.user.asObservable();
  private backuser = new BehaviorSubject<boolean>(false);
  public  backButton = this.backuser.asObservable();
  public editUser(newUser) {
    this.user.next(newUser);
  }
  public BackBUttoneditUser(newUser) {
    this.backuser.next(newUser);
  }
}
